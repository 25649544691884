// PickupPoint.js
import axiosInstance from './AxiosInstance';

/**
 * API for interacting with pickupPoint related endpoints.
 */
const PickupPointAPI = {
  /**
   * Retrieves all pickup points.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the pickupPoints.
   */
  getAllPickupPoints: () => {
    return axiosInstance.get('/pickupPoint');
  },
};

export default PickupPointAPI;

