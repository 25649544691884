<template>
  <div>
    <SubHeader />
    <CategoryNavigation class="mt-4 pb-2" />
    <router-view v-slot="{ Component }">
      <v-container class="pa-0 mt-8" style="min-height: 100vh">
        <CartModal />

        <v-container class="pa-0 ma-0">
          <KeepAlive>
            <component :is="Component"> </component>
          </KeepAlive>
        </v-container>
      </v-container>
    </router-view>
    <router-view v-slot="{ Component }" name="dialog">
      <div class="modal-container" :class="{ 'is-active': Component }">
        <div class="modal">
          <component :is="Component" />
        </div>
      </div>
    </router-view>
  </div>
</template>
<script></script>
