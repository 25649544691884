<template>
  <v-col algin="center" class="pa-0">
    <v-card
      v-if="isInCart(cartProduct ? product.productVariant._id : product._id)"
      color="primary"
      height="33"
      elevation="0"
    >
      <v-row class="pa-4" justify="center" align="center" cols="12">
        <v-col class="align-stretch pa-0" justify="center" align="center">
          <v-icon size="small" @click.stop="handleRemoveFromCart(product)"
            >mdi-minus</v-icon
          >
        </v-col>
        <v-col
          class="align-stretch pa-0"
          align="center"
          justify="center"
          sm="4"
        >
          <input
            aria-controls="none"
            class="input button pa-0"
            @focus="$event.target.select()"
            type="text"
            v-model="quantity"
            @blur="input"
            v-on:keyup.enter="$event.target.blur()"
            color="white"
          />
        </v-col>
        <v-col class="align-stretch pa-0" justify="center" align="center">
          <v-icon size="small" @click.stop="handleAddToCart(product)"
            >mdi-plus</v-icon
          >
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-else
      elevation="0"
      color="primary"
      class="text-none pa-0"
      block
      height="33"
      @click.stop="handleAddToCart(product)"
    >
      <v-row class="pa-4" justify="center" align="center" cols="12">
        <p class="button">{{ $t("add") }}</p></v-row
      >
    </v-card></v-col
  >
</template>
<script>
import { mapActions, mapState } from "vuex";
import DiscountIcon from "../assets/svg/discount.vue";

export default {
  name: "ProductButton",
  components: {
    DiscountIcon,
  },
  data() {
    return {
      quantity: "0",
      debounceTimer: null,
      debounceDelay: 700, // 1 second delay for debouncing actions
    };
  },
  props: {
    product: {
      required: true,
    },
    cartProduct: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState(["localCart"]),
    specificItemId() {
      // Replace 1 with the index or condition to find your specific object
      const variant = this.localCart.items.find((v) =>
        v.id === this.cartProduct
          ? this.product.productVariant._id
          : this.product._id
      );
      this.quantity = this.getQuantity(
        this.cartProduct ? this.product.productVariant._id : this.product._id
      ).toString();
      return variant ? variant : null;
    },
  },
  watch: {
    specificItemId(newCart, oldCart) {
      this.quantity = this.getQuantity(
        this.cartProduct ? this.product.productVariant._id : this.product._id
      ).toString();
    },
    quantity(newCount, oldCount) {
      // Remove non-numeric characters

      console.log(this.quantity);
      this.quantity = this.quantity.replace(/[^0-9]/g, "");
      console.log(this.quantity);

      if (this.quantity === "") {
        this.quantity = "0";
      }
      // Remove leading zeros (unless the value is just '0')
      if (this.quantity.length > 1 && this.quantity.startsWith("0")) {
        this.quantity = this.quantity.replace(/^0+/, "");
      }
      if (this.quantity.length > 3) {
        this.quantity = this.quantity.substring(0, 3);
      }

      console.log(this.quantity);
      console.log(newCount);

      return this.quantity;
    },
  },
  mounted() {
    this.quantity = this.getQuantity(
      this.cartProduct ? this.product.productVariant._id : this.product._id
    ).toString();
  },
  methods: {
    input() {
      console.log("input");
      this.changeProduct(parseInt(this.quantity));
    },
    ...mapActions([
      "addToCart",
      "removeFromCart",
      "changeLocalCart",
      "changeCart",
    ]),
    isInCart(id) {
      return this.localCart.items.some((variant) => variant.id === id);
    },
    changeProduct(quantity) {
      if (
        quantity !=
        this.getQuantity(
          this.cartProduct ? this.product.productVariant._id : this.product._id
        )
      ) {
        console.log("quantity", quantity);
        this.changeLocalCart({
          id: this.cartProduct
            ? this.product.productVariant._id
            : this.product._id,
          quantity: quantity,
        });
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(async () => {
          this.changeCart({
            id: this.cartProduct
              ? this.product.productVariant._id
              : this.product._id,
            quantity: quantity,
          });
        }, this.debounceDelay);
      }
    },
    getQuantity(id) {
      const variant = this.localCart.items.find((v) => v.id === id);
      return variant ? variant.quantity : 0;
    },

    handleProductClick(product) {
      console.log("click product", product);
      this.replace == true
        ? this.$router.replace({
            name: "ProductDetail",
            params: { id: product._id },
          })
        : this.$router.push({
            name: "ProductDetail",
            params: { id: product._id },
          });
    },
    handleAddToCart(product) {
      this.quantity = (parseInt(this.quantity) + 1).toString();
      this.changeProduct(parseInt(this.quantity));
    },
    handleRemoveFromCart(product) {
      console.log((parseInt(this.quantity) - 1).toString());
      this.quantity = (parseInt(this.quantity) - 1).toString();
      this.changeProduct(parseInt(this.quantity));
    },
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.input {
  width: 100%;
  color: white;
  border: 1px solid transparent;
  text-align: center;
}
.input:focus {
  outline: none !important;
  border: 1px solid white;
  border-radius: 2px;
}
.hover {
  cursor: pointer;
}
</style>
