<template>
  <!-- Main container for the cart modal, only shown if the cart is visible -->
  <v-navigation-drawer
    v-model="cartModalIsVisible"
    location="right"
    app
    :permanent="!$vuetify.display.xs"
    class="cartModal"
    :width="320"
    :style="$vuetify.display.xs || $vuetify.display.sm ? 'width: 100%' : ''"
  >
    <!-- Container for the content of the cart modal -->

    <v-container
      class="fill-height"
      fluid
      v-if="cart.productVariants.length === 0"
    >
      <v-col align="center" justify="center">
        <v-icon size="40">mdi-food-variant</v-icon>
        <p class="bold-h4">{{ $t("cart_is_empty") }}</p>
      </v-col>
    </v-container>
    <v-card height="100%" class="pt-5" style="overflow-y: auto" v-else>
      <v-card-title>
        <p class="bold-h2">{{ $t("cart") }}</p>
      </v-card-title>
      <v-card-text class="pa-0">
        <div>
          <v-list class="pa-1">
            <v-list-item
              v-for="(product, index) in cart.productVariants"
              :key="product.id"
              class="pa-1"
            >
              <template v-slot:prepend> </template>

              <v-card color="white" class="pa-2">
                <v-row>
                  <v-col
                    cols="3"
                    align="center"
                    justify="center"
                    @click="handleProductClick(product)"
                    class="hover"
                    style="position: relative"
                  >
                    <DiscountPrice
                      v-if="product.promotion"
                      style="
                        position: absolute;
                        top: 3px;
                        right: 0px;
                        z-index: 1000;
                      "
                      :size="{ width: 40, height: 40 }"
                      :promotion="product.promotion"
                      :isWeight="product.weightProduct"
                    />
                    <v-img
                      :src="product.productVariant.thumbnail.url"
                      alt="Product Image"
                      height="70"
                    />
                  </v-col>
                  <v-col align="space-between">
                    <v-row class="pa-2 pt-1">
                      <v-col class="pa-0">
                        <v-col sm="12" class="pa-0">
                          <p class="bold-1">
                            {{ product.productVariant.name }}
                          </p></v-col
                        >
                      </v-col>
                    </v-row>
                    <v-row class="pl-2 pr-3 mt-1" align="end">
                      <v-col class="pa-0" justify="center">
                        <v-col sm="12" class="pa-0 pr-1">
                          <p class="body-4">
                            {{ product.productVariant.manufacturer }}
                          </p></v-col
                        >
                        <v-col class="pa-0">
                          <span class="bold-1" v-if="product.totalDiscount > 0">
                            <span style="color: #c94631">
                              {{
                                (product.totalPrice != undefined
                                  ? product.totalPrice.toFixed(2)
                                  : "") + " kr"
                              }}</span
                            ><span
                              :style="{ textDecoration: 'line-through' }"
                              class="body-4"
                            >
                              {{
                                " " +
                                (product.totalPrice != undefined
                                  ? (
                                      product.totalPrice + product.totalDiscount
                                    ).toFixed(2)
                                  : "") +
                                " kr"
                              }}</span
                            >
                          </span>
                          <p class="bold-1" v-else>
                            {{
                              (product.totalPrice != undefined
                                ? product.totalPrice.toFixed(2)
                                : "") + " kr"
                            }}
                          </p>
                        </v-col></v-col
                      >
                      <v-col class="pa-0" cols="5">
                        <ProductButton :product="product" :cartProduct="true" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-list-item>

            <v-col justify="center" align="center">
              <v-dialog max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    v-bind="activatorProps"
                    color="error"
                    variant="flat"
                    class="xl-button text-none"
                  >
                    {{ $t("clean_cart") }}</v-btn
                  >
                </template>
                <template v-slot:default="{ isActive }">
                  <v-card :title="$t('confirm')">
                    <v-card-text>
                      {{ $t("are_you_sure_you_want_to_clear_cart") }}
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="isActive.value = false">
                        {{ $t("no") }}</v-btn
                      >

                      <v-btn @click="confirmClearCart"> {{ $t("yes") }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-col class="pa-0">
          <v-card color="white" elevation="0">
            <v-col justify="space-between">
              <v-col>
                <v-row justify="space-between">
                  <p class="bold-h4" style="color: #c94631">
                    {{ $t("total_discount") }}
                  </p>
                  <p class="bold-h4 error-text" style="color: #c94631">
                    {{ totalDiscount }} kr
                  </p>
                </v-row>
              </v-col>
              <v-col>
                <v-row justify="space-between">
                  <p class="bold-h4">
                    {{
                      $t("total") +
                      " (" +
                      $t("items_count", { count: totalCartItems }) +
                      ")"
                    }}
                  </p>
                  <p class="bold-h4">{{ totalCartPrice }} kr</p>
                </v-row>
              </v-col>

              <!-- Button to proceed to checkout -->
              <v-col> </v-col>
            </v-col>
          </v-card>
        </v-col>
      </v-card-actions>
      <!-- Custom confirmation dialog for clearing the cart -->

      <!-- Custom error dialog for checkout -->
      <div v-if="isCheckoutErrorVisible" class="confirm-clear-cart-overlay">
        <div class="confirm-clear-cart-modal">
          <h2 class="confirm-title">Fel</h2>
          <p>Något gick fel, försök igen senare</p>
          <div class="confirm-actions">
            <button
              class="confirm-btn confirm-yes"
              @click="isCheckoutErrorVisible = false"
            >
              OK
            </button>
          </div>
        </div>
      </div>
      <v-col
        class="continue pa-2 mt-2 pl-8 pr-8"
        v-if="cart.productVariants.length !== 0"
      >
        <v-btn
          class="pa-2 xl-button text-none"
          :loading="isCheckoutLoading"
          :diabled="isCheckoutLoading"
          @click="checkout"
          variant="elevated"
          elevation="24"
          size="large"
          color="success"
          block
        >
          {{ $t("order") }}
        </v-btn>
      </v-col>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { useRouter } from "vue-router";
import { mapFields } from "vuex-map-fields";
import ProductButton from "../ProductButton.vue";
import bag from "../../assets/images/bag.png";
import DiscountPrice from ".././DiscountPrice.vue";
export default {
  components: {
    ProductButton,
    DiscountPrice,
  },
  data() {
    return {
      bag: bag,
      // State variable to control the visibility of the clear cart confirmation dialog
      isConfirmClearCartVisible: false,
      // State variable to control the visibility of the checkout error dialog
      isCheckoutErrorVisible: false,
      // State variable to control the loading state of the checkout process
      isCheckoutLoading: false,
      drawer: true,
    };
  },
  computed: {
    ...mapFields(["cartModalIsVisible"]),
    ...mapState(["cart"]),
    /**
     * Calculates the total price of the items in the cart.
     * @return {string} The total price formatted to 2 decimal places.
     */
    totalCartPrice() {
      return this.cart.price.products?.toFixed(2) || "0.00";
    },
    /**
     * Calculates the total discount from productDiscount and cartDiscount.
     * @return {string} The total discount formatted to 2 decimal places.
     */
    totalDiscount() {
      const productDiscount = this.cart.price.productDiscount || 0;
      const cartDiscount = this.cart.price.cartDiscount || 0;
      return (productDiscount + cartDiscount).toFixed(2);
    },
    /**
     * Gets the total number of items in the cart.
     * @return {number} The total number of items in the cart.
     */
    totalCartItems() {
      return this.cart.totalItems;
    },
  },
  methods: {
    ...mapActions([
      "toggleCartModal",
      "addToCart",
      "removeFromCart",
      "clearCart",
      "checkoutCart",
    ]),
    handleProductClick(product) {
      console.log("click product", product);
      this.$router.push({
        name: "ProductDetail",
        params: { id: product.productVariant._id },
      });
    },
    /**
     * Closes the cart modal when clicking outside of it.
     */
    closeOnOutsideClick() {
      this.toggleCartModal();
    },
    /**
     * Handles the checkout process.
     * Displays a loading indicator while processing and shows an error message if it fails.
     */

    async checkout() {
      this.isCheckoutLoading = true;
      try {
        await this.checkoutCart();
        this.toggleCartModal(); // Close the cart modal
        this.$router.push({ name: "cart" });
      } catch (error) {
        this.isCheckoutErrorVisible = true;
      } finally {
        this.isCheckoutLoading = false;
      }
    },

    /**
     * Shows the confirmation dialog for clearing the cart.
     */
    showConfirmClearCart() {
      this.isConfirmClearCartVisible = true;
    },
    /**
     * Clears the cart and hides the confirmation dialog.
     * Redirects to the homepage if the current route is payment-related.
     */
    async confirmClearCart() {
      await this.clearCart();
      this.isConfirmClearCartVisible = false;
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>

<style scoped>
.hover {
  cursor: pointer;
}
.continue {
  position: sticky;
  bottom: 10px;
  z-index: 100;
}
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cart-modal-parent {
  position: absolute;
  top: 142px;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.2);
}

.cart-modal-content {
  width: 300px;
  height: 100vh;
  background: #f8f9f8;
  position: sticky;
  top: 0;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.control-container {
  padding: 20px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2em;
  color: black;
}

.cart-modal-title {
  font-size: 26px;
}

.cart-items-container {
  background-color: #ffffff;
  overflow-y: auto;
  height: 70%;
  padding: 20px;
}

.cart-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.item-left {
  display: flex;
  flex-direction: row;
}

.item-right {
  display: flex;
}

.product-image-container {
  position: relative;
  display: flex;
  align-items: center;
}

.product-image {
  width: auto;
  height: 75px;
  width: 100px;
  object-fit: contain;
}

.item-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  margin-left: 20px;
  max-width: 50%; /* Adjust the max-width as needed */
}

.item-name {
  font-weight: bold;
}

.item-price {
  color: #333;
  margin-top: 15px;
}

.item-amount-container {
  display: flex;
  font-size: 28px;
  align-items: center;
}

.remove-cart {
  margin: 5px;
  background-color: white;
  min-width: 35px;
  max-height: 35px;
  border-radius: 17.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.item-amount {
  font-size: 18px;
  min-width: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.add-cart {
  margin: 5px;
  background-color: #003a70;
  color: white;
  min-width: 35px;
  max-height: 35px;
  border-radius: 17.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.checkout-container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: auto;
  background-color: white;
  position: sticky;
  bottom: 0px;
}

.checkout-btn {
  background-color: #003a70;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  self-align: center;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-btn:hover {
  background-color: #005092;
}

.clear-cart-btn {
  background-color: #c94631;
  color: white;
  border: none;
  font-weight: bold;
  padding: 20px 20px;
  cursor: pointer;
  width: 60%;
  self-align: center;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.clear-cart-btn:hover {
  background-color: #d9534f;
}

.price-container {
  width: 100%;
  height: 30vh;
}

.price-discount-container {
  margin-bottom: 10px;
  margin-top: 10px;
  color: #c94631;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.price-total-container {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* Custom Confirmation Dialog Styles */
.confirm-clear-cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-clear-cart-modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 80%;
  max-width: 400px;
}

.confirm-title {
  font-size: 22px;
  margin-bottom: 20px;
}

.confirm-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm-btn {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-yes {
  background-color: #c94631;
  color: white;
}

.confirm-yes:hover {
  background-color: #d9534f;
}

.confirm-no {
  background-color: #003a70;
  color: white;
}

.confirm-no:hover {
  background-color: #005092;
}

/* Phone styles*/

@media (max-width: 425px) {
  .cart-modal-content {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
  }

  .cart-modal-parent {
    height: 100%;
    background-color: white;
    z-index: 1000;
  }

  .checkout-container {
    position: relative;
    padding: 20px;
  }

  .item-amount-container {
    flex-direction: column-reverse;
  }

  .checkout-btn {
    margin-bottom: 70px;
  }
}
</style>
