<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <div>
          <h1 class="bold-h1">{{ $t("saved") }}</h1>
          <p class="bold-h3">{{ $t("my_lists") }}</p>
          <v-row
            justify="center"
            align="center"
            class="pa-0 mt-4"
            v-if="createdLists.length > 0"
          >
            <v-col cols="12" lg="10" xl="10" class="pa-0">
              <v-row justify="center" align="center" class="pa-0">
                <v-col
                  class="custom4cols"
                  v-for="list in createdLists"
                  :key="list._id"
                >
                  <ListCard
                    :key="list._id"
                    :list="list"
                  /> </v-col></v-row></v-col
          ></v-row>
          <p v-else>{{ $t("no_results") }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Header from "@/components/Header.vue";
import ListRow from "@/components/ListRow.vue";
import ShoppingListAPI from "@/api/SaveAPI.js";
import ListCard from "@/components/ListCard.vue";
export default {
  name: "SavedListsPage",
  components: {
    Header,
    ListRow,
    ListCard,
  },
  data() {
    return {
      createdLists: [],
      likedLists: [],
      loadingCreated: true,
      loadingLiked: true,
      createListModalVisible: false,
      newListName: "",
    };
  },
  methods: {
    ...mapActions(["addToCart"]),
    async loadCreatedLists() {
      try {
        const response = await ShoppingListAPI.getUserOwnShoppingLists();
        this.createdLists = response.data.map((list) => ({
          ...list,
          products: list.activeProducts || [],
        }));
      } catch (error) {
        console.error("Error loading created lists:", error);
      } finally {
        this.loadingCreated = false;
      }
    },
    async loadLikedLists() {
      try {
        const response = await ShoppingListAPI.getUserLikedShoppingLists();
        this.likedLists = response.data.map((list) => ({
          ...list,
          products: list.activeProducts || [],
        }));
      } catch (error) {
        console.error("Error loading liked lists:", error);
      } finally {
        this.loadingLiked = false;
      }
    },
    showCreateListModal() {
      this.createListModalVisible = true;
    },
    closeCreateListModal() {
      this.createListModalVisible = false;
    },
    async createNewList() {
      if (this.newListName.trim() !== "") {
        try {
          const newList = {
            name: this.newListName,
            products: [],
            onlineAvailability: true,
          };
          const response = await ShoppingListAPI.createShoppingList(newList);
          this.createdLists.push({
            ...response.data,
            products: response.data.products || [],
          });
          this.newListName = "";
          this.createListModalVisible = false;
        } catch (error) {
          console.error("Error creating new list:", error);
        }
      }
    },
    navigateToList(listId) {
      this.$router.push({ name: "saved", query: { listId } });
    },
    tempLog() {
      console.log("temp");
    },
    updateCreatedLists(newLists) {
      this.createdLists = newLists;
    },
    updateLikedLists(newLists) {
      this.likedLists = newLists;
    },
  },
  async activated() {
    await this.loadCreatedLists();
    await this.loadLikedLists();
  },
};
</script>

<style scoped>
.custom4cols {
  width: 25%;
  max-width: 250px;
  min-width: 200px;
  flex-basis: 20%;
}
.max-width-container {
  max-width: 1200px;
  margin-bottom: 142px;
}

.breadcrumbs {
  padding-left: 0;
  margin-bottom: 20px;
}

.saved-lists-page {
  padding: 20px;
  margin-top: 142px;
}

.page-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 80%;
  max-width: 400px;
}

.modal-title {
  font-size: 22px;
  margin-bottom: 20px;
}

.modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
}

.modal-btn {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-btn.create {
  background-color: #003a70;
  color: white;
}

.modal-btn.create:hover {
  background-color: #005092;
}

.modal-btn.cancel {
  background-color: #c94631;
  color: white;
}

.modal-btn.cancel:hover {
  background-color: darkred;
}
</style>
