<template>
  <v-navigation-drawer
    v-model="locationModalIsVisible"
    temporary
    width="380"
    location="right"
    :style="!$vuetify.display.xs ? '' : 'width: 100%'"
  >
    <v-row>
      <v-col class="pa-4">
        <v-row
          align="start"
          :justify="step > 1 ? 'space-between' : 'end'"
          class="pa-0"
        >
          <v-btn
            v-if="step > 1"
            class="ma-2"
            elevation="0"
            @click="step--"
            icon="mdi-arrow-left"
            color="surface"
            size="small"
          ></v-btn>
          <v-btn
            class="ma-2"
            elevation="0"
            @click="openLocationSelection"
            icon="mdi-close"
            color="surface"
            size="small"
          ></v-btn>
        </v-row>
        <v-window disabled v-model="step">
          <v-window-item disabled :value="1">
            <v-col class="pa-0" align="center">
              <p class="text-h5">{{ $t("pickup_locations") }}</p>
              <v-row cols="12" class="mt-5 pa-2">
                <v-col
                  cols="6"
                  sm="6"
                  class="pa-2"
                  v-for="location in pickupPoints"
                  :key="location._id"
                >
                  <v-card
                    @click="selectPickupLocation(location._id)"
                    style="height: 100%"
                    class="pa-2 locker"
                    elevation="0"
                  >
                    <v-col class="pa-0">
                      <v-col sm="8" align="start" justify="start" class="pa-0">
                        <v-img :src="locker" contain></v-img>
                      </v-col>
                      <div class="card-title">
                        {{ location.name }}
                      </div>
                      <div class="card-address">{{ location.address }}</div>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-window-item>
          <v-window-item disabled :value="2">
            <v-col class="pa-0" align="center">
              <p class="text-h5">{{ $t("pickup_times") }}</p>
              <v-progress-circular
                v-if="pickupTimesLoading"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-col v-else class="pa-0">
                <v-window v-model="window" show-arrows>
                  <template v-slot:prev="{ props }">
                    <v-btn size="large" @click="props.onClick" variant="text">
                      <v-icon size="30">mdi-chevron-left</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:next="{ props }">
                    <v-btn size="large" @click="props.onClick" variant="text">
                      <v-icon size="30">mdi-chevron-right</v-icon>
                    </v-btn>
                  </template>
                  <v-window-item
                    v-for="(date, index) in next14Days"
                    :key="index"
                    :value="index"
                  >
                    <v-card
                      elevation="0"
                      class="d-flex justify-center align-center"
                    >
                      <span class="text-h6">
                        {{ getDayLabel(date, true) }}</span
                      >
                    </v-card>
                  </v-window-item>
                </v-window>
                <div v-for="(date, index) in next14Days">
                  <v-col v-if="window === index">
                    <v-row class="mt-2">
                      <v-col
                        v-if="
                          pickupTimes.filter(
                            (element) =>
                              isSameDate(element.orderBefore, date) === true
                          ).length > 0
                        "
                        cols="6"
                        sm="6"
                        class="pa-1"
                        v-for="(time, index) in pickupTimes.filter(
                          (element) =>
                            isSameDate(element.orderBefore, date) === true
                        )"
                      >
                        <v-card
                          @click="choosePickupTime(time)"
                          class="pa-2 locker"
                          elevation="0"
                          ><v-col>
                            <p class="font-weight-bold">
                              {{
                                formatTime(time.pickupAt) +
                                " - " +
                                formatTime(time.pickupBefore)
                              }}
                            </p>
                            <p>{{ time.price.toFixed(2) + " kr" }}</p>
                            <p class="font-italic">
                              {{
                                $t("order_by") +
                                " " +
                                formatTime(time.orderBefore)
                              }}
                            </p>
                          </v-col>
                        </v-card>
                      </v-col>
                      <v-col v-else> {{ $t("no_available_times") }}</v-col>
                    </v-row>
                  </v-col>
                </div>
              </v-col>
            </v-col>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import locker from "../../assets/images/ns-locker.png";
import PickupTimeAPI from "@/api/PickupTimeAPI"; // Import the UserAPI
import { formatTime, getDayLabel } from "../../utils.js";
import PickupPointAPI from "@/api/PickupPointAPI";
export default {
  computed: {
    ...mapFields(["locationModalIsVisible"]),
    previouslySelectedLocation() {
      return this.location.pickupLocation;
    },
    leftButtonStyle() {
      return {
        color: this.scrolledToLeft ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 1)",
      };
    },
    rightButtonStyle() {
      return {
        color: this.scrolledToRight ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 1)",
      };
    },
    shouldShowScrollButtons() {
      return this.pickupPoints.length > 3;
    },
    cardWidthStyle() {
      const cardCount = this.pickupPoints.length;
      const visibleCards = Math.min(cardCount, 3); // Limit to a maximum of 3 cards
      return {
        width: `calc(100% / ${visibleCards} - 15px)`,
      };
    },
    isPhone() {
      return window.innerWidth <= 600;
    },
  },
  watch: {
    async locationModalIsVisible(newVal, oldVal) {
      if (newVal == true) {
        const resp = await PickupPointAPI.getAllPickupPoints();
        this.pickupPoints = resp.data;
        if (this.selectedPickupLocation != "") {
          const response = await PickupTimeAPI.getAllPickupTimes(
            this.selectedPickupLocation
          );
          this.generateNext14Days();
          this.pickupTimes = response.data;
        }
      }
    },
  },
  async mounted() {
    const resp = await PickupPointAPI.getAllPickupPoints();
    this.pickupPoints = resp.data;
  },
  created() {
    this.generateNext14Days();
  },
  methods: {
    ...mapActions([
      "selectLocation",
      "toggleLocationModal",
      "updateSelectedPickupTime",
    ]),
    formatTime,
    getDayLabel,
    generateNext14Days() {
      const days = [];
      const today = new Date();
      for (let i = 0; i < 14; i++) {
        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + i);
        days.push(nextDay);
      }
      this.next14Days = days;
    },

    isSameDate(inDate, checkDate) {
      const date = new Date(inDate);
      const today = new Date(checkDate);

      // Remove time component from both dates to compare only the date part
      const todayDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const inputDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      const diffTime = inputDate - todayDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(diffDays === 0);
      if (diffDays === 0) {
        return true; // "Today" in Swedish
      } else {
        false;
      }
    },
    async choosePickupTime(pickupTime) {
      console.log(pickupTime);
      const location = this.pickupPoints.find(
        (loc) => loc._id === pickupTime.pickUpPoint
      );
      console.log(location);
      if (location) {
        this.selectedPickupLocation = location._id;
        await this.selectLocation({
          storeId: location.warehouseId,
          pickupLocation: this.selectedPickupLocation,
          pickupTime: pickupTime,
        });
        console.log("done selecting");
      }
      this.toggleLocationModal();
    },

    openLocationSelection() {
      this.toggleLocationModal();
    },
    /**
     * Selects a pickup location.
     * @param {string} locationId - The ID of the location to select.
     */
    async selectPickupLocation(locationId) {
      this.step = 2;
      this.selectedPickupLocation = locationId;
      const response = await PickupTimeAPI.getAllPickupTimes(locationId);
      this.generateNext14Days();
      this.window = 0;
      this.pickupTimes = response.data;
      this.pickupTimesLoading = false;
    },

    /**
     * Scrolls the pickup location container.
     * @param {number} direction - The direction to scroll (-1 for left, 1 for right).
     */
    scroll(direction) {
      const container = this.$refs.scrollRow;
      const scrollAmount = container.clientWidth;
      container.scrollBy({
        left: direction * scrollAmount,
        behavior: "smooth",
      });
    },
    /**
     * Checks the scroll position of the pickup location container.
     */
    checkScrollPosition() {
      const container = this.$refs.scrollRow;
      const gapSize = 20;
      const maxScrollLeft =
        container.scrollWidth - container.clientWidth - gapSize;
      this.scrolledToLeft = container.scrollLeft <= 0;
      this.scrolledToRight = container.scrollLeft >= maxScrollLeft;
    },
  },
  data() {
    return {
      selectedPickupLocation: "",
      scrolledToLeft: true,
      scrolledToRight: false,
      pickupTimes: {},
      pickupPoints: [],
      pickupTimesLoading: true,
      step: 1,
      model: null,
      next14Days: [],
      activeTab: null,
      length: 3,
      locker: locker,
      window: 0,
    };
  },
};
</script>

<style scoped>
.locker {
  border: 2px solid #7577788f;
}
.locker:hover {
  border: 2px solid #4caf50;
}
/* General Modal Styles */
.location-selection-parent {
  position: absolute;
  bottom: 0;
  height: calc(100% - 72px);
  width: 100%;
  overflow-y: auto;
  z-index: 1000;
}

.location-selection-parent-mandatory {
  position: fixed;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  z-index: 10000;
}

/* Modal Overlay Styles */
.location-selection-modal-overlay {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100vw;
  height: 100%;
}

.location-selection-modal-overlay-mandatory {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100%;
}

/* Modal Content Styles */
.location-selection-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px;
  background: #f8f9f8;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Header Styles */
h2 {
  text-align: left;
  font-size: 20px;
}

/* Control Container Styles */
.control-container {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}

.location-selection-title {
  margin-right: 45px;
}

/* Conditional Styling for Mandatory State */
.location-selection-parent-mandatory .control-container,
.location-selection-parent-mandatory .pickup-location-container {
  margin-right: 0;
  margin-top: 0;
}

/* Pickup Location Container Styles */
.pickup-location-container {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  margin-top: 20px;
  padding-bottom: 15px;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  margin-right: 30px;
}

/* Individual Pickup Location Card Styles */
.pickup-location-card {
  height: 180px;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
  flex: 0 0 auto;
  background-color: white;
  width: calc(100% / 3 - 15px); /* Fallback static width */
  scroll-snap-align: start;
}

.selected-card {
  border-width: 3px !important;
}

.card-content {
  margin-bottom: 10px;
}

/* Card Content Styles */
.card-title {
  font-weight: bold;
  color: #003a70;
}

.card-address {
  font-size: 0.9em;
  color: #555;
}

.button-container {
  margin-top: 50px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2em;
  color: black;
}

.select-location-btn {
  background-color: #003a70;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

.select-location-btn:hover {
  background-color: #005092;
}

/* Scroll Button Styles */
.scroll-btn {
  width: 45px;
  height: 30px;
  position: relative;
  background-color: white;
  font-weight: bold;
  color: black;
  border: solid;
  border-width: 1px;
  cursor: pointer;
  z-index: 2;
}

.scroll-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: 2px solid black;
}

.scroll-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: 2px solid black;
}

/* Media queries for mobile view */
@media (max-width: 600px) {
  .location-selection-modal-overlay {
    position: fixed;
    align-items: center;
    justify-content: center;
  }
  .location-selection-modal-content {
    position: relative;
    width: 90%;
  }

  .location-selection-parent {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
</style>
