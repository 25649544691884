<template>
  <v-row class="ma-2">
    <v-col sm="12" class="pa-0" align="center" justify="center" v-if="loading">
      <v-progress-circular color="primary" height="10" indeterminate />
    </v-col>
    <v-row v-else justify="center">
      <v-col cols="12" sm="10" lg="10" xl="8" class="pa-4">
        <p class="bold-h1">{{ $t("my_orders_plural") }}</p>
        <div v-if="activeOrders.length > 0 || previousOrders > 0">
          <v-col v-if="activeOrders.length > 0">
            <p class="bold-h3">{{ $t("active_orders") }}</p>
            <v-row justify="start">
              <v-col sm="12" justify="center" align="center">
                <v-card
                  color="surface"
                  elevation="0"
                  v-for="(order, index) in activeOrders"
                  :key="order.id"
                  class="pa-0 mt-4"
                  :to="{
                    name: 'order',
                    params: {
                      id: order._id,
                    },
                  }"
                >
                  <v-card-text>
                    <v-row align="center" justify="start">
                      <v-col class="pa-0">
                        <Vue3Lottie
                          animation-link="https://assets2.lottiefiles.com/packages/lf20_iawwfiag.json"
                        />
                      </v-col>
                      <v-col align="start" justify="start" cols="8" sm="9">
                        <p class="bold-h3" style="color: #003a70">
                          Nr.
                          {{ order.shortOrderNum.toString().padStart(4, "0") }}
                        </p>
                        <p
                          class="bold-h4 pt-1"
                          style="color: #4caf50"
                          v-if="order.status != 'waiting'"
                        >
                          {{ $t(order.status) }}
                        </p>
                        <v-btn
                          v-else
                          @click.prevent="dialog = true"
                          class="mt-3 button text-none"
                          flat
                          color="primary"
                          size="x-large"
                        >
                          {{ $t("pick_up") }}
                        </v-btn>
                        <v-dialog max-width="500" v-model="dialog">
                          <v-card :title="$t('open_lockers')">
                            <v-card-text>
                              {{ $t("are_you_sure_you_want_to_open_lockers") }}
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn @click="dialog = false">{{
                                $t("no")
                              }}</v-btn>
                              <v-btn
                                :loading="dialogIsLoading"
                                @click="collectOrder(order._id)"
                                >{{ $t("yes") }}</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <p class="pt-4 body-1">
                          {{
                            order.pickupPoint.name +
                            " " +
                            getDayLabel(order.pickupTimeSlot.pickupAt, false) +
                            " " +
                            formatTime(order.pickupTimeSlot.pickupAt) +
                            " - " +
                            formatTime(order.pickupTimeSlot.pickupBefore)
                          }}
                        </p>
                        <p class="bold-h4 mt-1">
                          {{
                            $t("items_count", { count: order.totalItems }) +
                            " " +
                            order.price.payment.toFixed(2) +
                            " kr"
                          }}
                        </p></v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="previousOrders.length > 0">
            <p class="bold-h3">{{ $t("previous_orders") }}</p>
            <v-row justify="start">
              <v-col sm="12" justify="center" align="center">
                <v-card
                  color="surface"
                  elevation="0"
                  v-for="(order, index) in previousOrders"
                  :key="order.id"
                  class="pa-2 mt-4"
                  :to="{
                    name: 'order',
                    params: {
                      id: order._id,
                    },
                  }"
                >
                  <v-card-title>
                    <v-row align="center" justify="space-between" class="pa-4">
                      <v-row align="center" class="pa-2">
                        <p class="bold-h3 pl-1" style="color: #003a70">
                          Nr.
                          {{ order.shortOrderNum.toString().padStart(4, "0") }}
                        </p>
                        <p class="body-2 pl-4 pt-1">
                          {{
                            order.pickupPoint.name +
                            " " +
                            getDayLabel(order.deliveredAt, false) +
                            " " +
                            formatTime(order.deliveredAt)
                          }}
                        </p></v-row
                      >
                      <p class="bold-h4 pl-0">
                        {{
                          $t("items_count", { count: order.totalItems }) +
                          " " +
                          order.price.total.toFixed(2) +
                          " kr"
                        }}
                      </p></v-row
                    >
                  </v-card-title>
                </v-card>
              </v-col></v-row
            >
          </v-col>
        </div>
        <v-col v-else> Du har inte lagt några ordrar ännu </v-col>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import OrderAPI from "@/api/OrderAPI";
import { formatTime, getDayLabel } from "../utils.js";
import { mapActions } from "vuex";
import { Vue3Lottie } from "vue3-lottie";
import { onActivated, onDeactivated } from "vue";
export default {
  name: "OrdersPage",
  data() {
    return {
      orderTimer: null,
      activeOrders: {},
      previousOrders: {},
      loading: true,
      dialog: false,
      dialogIsLoading: false,
    };
  },

  deactivated() {
    console.log("unmounting...");
    clearInterval(this.orderTimer);
  },
  async activated() {
    try {
      await this.fetchOrders();
      this.loading = false;
      this.orderTimer = setInterval(this.fetchOrders, 5000);
      setTimeout(() => {
        this.initializeCart();
      }, 1000);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  },
  methods: {
    getDayLabel,
    formatTime,
    ...mapActions(["initializeCart"]),
    async fetchOrders() {
      const respActive = await OrderAPI.getUserOrders({ isActive: true });
      const respPrev = await OrderAPI.getUserOrders({ isActive: false });
      this.activeOrders = respActive.data;
      this.activeOrders = this.activeOrders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      this.previousOrders = respPrev.data;
      this.previousOrders = this.previousOrders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    },
    async collectOrder(id) {
      this.dialogIsLoading = true;
      await OrderAPI.collectOrder(id);
      await this.fetchOrders();
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
/* Add any styling here if needed */
</style>
