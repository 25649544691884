<template>
  <v-col>
    <v-col>
      <v-row justify="center" align="center" class="mt-6">
        <v-col cols="12" sm="8" justify="center" align="center">
          <p class="bold-h1">{{ $t("how_does_works") }}</p>
          <p class="body-2 mt-4">
            {{ $t("description") }}
          </p>
        </v-col></v-row
      >
      <v-row justify="space-between" class="mt-8">
        <v-col align="center" cols="12" sm="3">
          <v-icon size="35" color="primary"> mdi-cart </v-icon>
          <p class="bold-h3">{{ $t("step_1") }}</p>

          <p class="body-2 mt-2">{{ $t("order_in_app_or_web") }}</p>
        </v-col>
        <v-col align="center" cols="12" sm="3">
          <v-icon size="35" color="primary"> mdi-map-marker </v-icon>

          <p class="bold-h3">{{ $t("step_2") }}</p>
          <p class="body-2 mt-2">{{ $t("choose_pickup_time_location") }}</p>
        </v-col>
        <v-col align="center" cols="12" sm="3">
          <v-icon size="35" color="primary"> mdi-forklift </v-icon>
          <p class="bold-h3">{{ $t("step_3") }}</p>
          <p class="body-2 mt-2">
            {{ $t("we_pick_order_ready_in_hours") }}
          </p>
        </v-col>
        <v-col align="center" cols="12" sm="3">
          <v-icon size="35" color="primary"> mdi-flag-checkered </v-icon>
          <p class="bold-h3">{{ $t("step_4") }}</p>
          <p class="body-2 mt-2">{{ $t("collect_items_pickup_location") }}</p>
        </v-col>
      </v-row>
    </v-col>
    <v-row class="mt-10 ml-10 mr-10" justify="center">
      <p class="bold-h1" align="center">
        {{ $t("benefits_of_nearbystore") }}
      </p>
    </v-row>
    <v-row class="mr-10 mt-10 ml-10 mb-10" justify="space-evenly">
      <v-col cols="12" sm="5" class="pa-1">
        <v-card class="rounded-lg" flat color="background">
          <v-img :src="locker" cover class="rounded-xl"></v-img
        ></v-card> </v-col
      ><v-col
        cols="12"
        sm="5"
        class="d-flex flex-column justify-space-evenly pa-0"
      >
        <v-row align="center" class="mt-2">
          <v-col cols="2" sm="1" class="pa-0">
            <v-card class="pt-2 pb-2" color="secondary" flat align="center"
              ><v-icon size="25" color="primary">mdi-truck</v-icon></v-card
            >
          </v-col>
          <v-col class="pa-2">
            <p class="bold-h4 pb-2">{{ $t("low_shipping_costs") }}</p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="2" sm="1" class="pa-0">
            <v-card class="pt-2 pb-2" color="secondary" flat align="center"
              ><v-icon size="25" color="primary">mdi-package</v-icon></v-card
            >
          </v-col>
          <v-col class="pa-2">
            <p class="bold-h4 pb-2">{{ $t("same_day_pickup") }}</p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="2" sm="1" class="pa-0">
            <v-card class="pt-2 pb-2" color="secondary" flat align="center"
              ><v-icon size="25" color="primary">mdi-gift</v-icon></v-card
            >
          </v-col>
          <v-col class="pa-2">
            <p class="bold-h4 pb-2">
              {{ $t("free_shipping_bonus") }}
            </p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="2" sm="1" class="pa-0">
            <v-card class="pt-2 pb-2" color="secondary" flat align="center"
              ><v-icon size="25" color="primary">mdi-trophy</v-icon></v-card
            >
          </v-col>
          <v-col class="pa-2">
            <p class="bold-h4 pb-2">{{ $t("no_minimum_purchase_fees") }}</p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="2" sm="1" class="pa-0">
            <v-card class="pt-2 pb-2" color="secondary" flat align="center"
              ><v-icon size="25" color="primary"
                >mdi-information</v-icon
              ></v-card
            >
          </v-col>
          <v-col class="pa-2">
            <p class="bold-h4 pb-2">{{ $t("wide_selection_low_prices") }}</p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="2" sm="1" class="pa-0">
            <v-card class="pt-2 pb-2" color="secondary" flat align="center"
              ><v-icon size="25" color="primary">mdi-trophy</v-icon></v-card
            >
          </v-col>
          <v-col class="pa-2">
            <p class="bold-h4 pb-2">
              {{ $t("extra_low_prices_for_members") }}
            </p>
          </v-col>
        </v-row>
        <v-row align="center" class="pl-0 mt-8">
          <v-btn
            flat
            block
            size="x-large"
            color="error"
            @click="goLocationModal"
            class="text-none button"
          >
            <v-icon class="mr-2">mdi-map-marker</v-icon
            >{{ $t("see_pickup_locations_near_me") }}</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import bag2 from "../assets/images/bag.png";
import bag from "../assets/images/matkasse.png";
import food_splash from "../assets/images/food.png";
import food_half from "../assets/images/food_half.png";
import star from "../assets/images/star.png";
import locker from "../assets/images/ns-locker.png";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      bag: bag,
      bag2: bag2,
      locker: locker,
      food_half: food_half,
      food_splash: food_splash,
      star: star,
    };
  },
  methods: {
    ...mapActions([
      "toggleLocationModal",
      "setCategoryModal",
      "toggleCartModal",
      "userLoggedOut",
    ]),
    async goLocationModal() {
      await this.$router.push({ name: "handla" });
      this.toggleLocationModal();
    },
  },
  async mounted() {},
};
</script>
