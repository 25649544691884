<template>
  <div>
    <p v-if="isOverADay">
      {{ getDayLabel(targetDate, false) + " " + formatTime(targetDate) }}
    </p>
    <p class="body-2" v-else style="color: #c94631">
      {{ minutes + " " + $t("min") + " " + seconds + " " + $t("sec") }}
    </p>
  </div>
</template>

<script>
import { formatTime, getDayLabel } from "../utils.js";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  props: {
    targetDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      minutes: 0,
      seconds: 0,
      isOverADay: false,
      interval: null,
    };
  },
  computed: {
    ...mapState(["cart"]),
  },
  mounted() {
    this.countDown();
    this.interval = setInterval(this.countDown, 1000);
  },
  methods: {
    ...mapActions([
      "toggleLocationModal",
      "setCategoryModal",
      "initializeCart",
      "userLoggedOut",
    ]),
    formatTime,
    getDayLabel,
    countDown() {
      const now = new Date().getTime();
      const target = new Date(this.targetDate).getTime();
      const remainingTime = target - now;
      if (this.cart.pickupTime !== null && this.cart.pickupTime !== undefined) {
        if (remainingTime <= 0) {
          this.isOverADay = false;
          this.minutes = 0;
          this.seconds = 0;
          if (remainingTime <= -(1000 * 60 * 3)) {
            this.initializeCart();
          }
        } else if (remainingTime > 60 * 60 * 1000) {
          this.isOverADay = true;
        } else {
          this.isOverADay = false;
          this.minutes = Math.floor(remainingTime / (1000 * 60));
          this.seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        }
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
