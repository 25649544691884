<template>
  <div class="subcategory-navigation-wrapper">
    <div class="subcategory-navigation">
      <div
        :class="[
          'bold-h4 pb-1',
          'subcategory-bubble',
          {
            'selected-bubble': selectedSubcategoryId === null,
          },
        ]"
        @click="navigateToMain()"
      >
        {{ $t("all") }}
      </div>
      <div
        v-for="subcategory in subcategories"
        :key="subcategory._id"
        :class="[
          'bold-h4 pb-1 mb-2',
          'subcategory-bubble',
          {
            'selected-bubble':
              subcategory.category._id === selectedSubcategoryId,
          },
        ]"
        @click="navigateToSubcategory(subcategory.category._id)"
      >
        {{ subcategory.category.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "SubcategoryNavigation",
  props: {
    parentId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    return { route, router };
  },
  computed: {
    ...mapGetters(["categories"]),
    subcategories() {
      console.log(this.categories);
      return this.categories.filter(
        (cat) => cat.category.parent === this.parentId
      );
    },
    selectedSubcategoryId() {
      return this.route.params.subcategoryName
        ? `${this.route.params.categoryName}.${this.route.params.subcategoryName}`
        : null;
    },
  },
  methods: {
    navigateToSubcategory(subcategoryId) {
      this.router.push({
        name: "category",
        params: {
          categoryName: this.parentId,
          subcategoryName: subcategoryId.split(".").pop(),
        },
      });
    },
    navigateToMain() {
      this.router.push({
        name: "category",
        params: {
          categoryName: this.parentId,
        },
      });
    },
  },
};
</script>

<style scoped>
.subcategory-navigation-wrapper {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.subcategory-navigation {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 4px;
  gap: 10px;
  background-color: transparent;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.subcategory-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  height: 40px;
  border-radius: 10px;
  background-color: #f5f5f9;
  color: black;
  cursor: pointer;
  flex-shrink: 0;
  padding: 0 10px;
  text-align: center;
  white-space: nowrap;
  scroll-snap-align: start;
}

.selected-bubble {
  background-color: #003a70;
  color: #f5f5f9;
}
</style>
