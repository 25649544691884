/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App
 */

import router from "@/plugins/router"; // Import the router
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";

import { registerPlugins } from "@/plugins";
import "./styles/style.scss";
import "flag-icons/css/flag-icons.min.css";
import "v-phone-input/dist/v-phone-input.css";
import { createVPhoneInput } from "v-phone-input";
import i18n from "@/plugins/i18n";
import Vue3Lottie from "vue3-lottie";
import * as Sentry from "@sentry/vue";
import { auth } from "./plugins/firebaseconfig.js";
const vPhoneInput = createVPhoneInput({
  countryIconMode: "svg",
});

const app = createApp(App);

const isProduction = import.meta.env.VITE_APP_ENV === "production";
console.log("isprod", isProduction);
Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_ADDRESS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(), // Make sure to use the correct integration method if needed
  ],
  environment: import.meta.env.VITE_APP_FIREBASE_DEVELOPMENT,
  // Tracing
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: isProduction ? 0.1 : 0, // 10% in production, 0% otherwise
  replaysOnErrorSampleRate: isProduction ? 1.0 : 0, // 100% in production, 0% otherwise
});
// Register other plugins
registerPlugins(app);

// Register the Stripe plugin

// Use Vuex store
app.use(store);

// Use Vue Router
app.use(router);
app.use(vPhoneInput);
app.use(i18n);
app.use(Vue3Lottie);
// Dispatch the initializeApp action
store.dispatch("initializeApp");
auth.onAuthStateChanged(async (user) => {
  console.log(user);
});
// Mount the app
app.mount("#app");
