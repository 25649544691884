<template>
  <v-snackbar
    :color="color"
    :timeout="timer"
    v-model="showSnackbar"
    :location="location"
  >
    <v-icon v-if="icon !== ''" class="mr-2">{{ icon }}</v-icon
    >{{ message }}
    <template v-slot:actions>
      <v-btn
        color="white"
        icon="mdi-close"
        variant="text"
        size="small"
        @click.stop="showSnackbar = false"
      >
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "vtoast",
  data() {
    return {
      showSnackbar: false,
      message: "",
      color: "success",
      icon: "mdi-check",
      location: "top",
      timer: 3000,
    };
  },
  methods: {
    show(data) {
      this.message = data.message || 'missing "message".';
      this.color = data.color || "success";
      this.timer = data.timer || 3000;
      this.icon = data.icon || "";
      this.location = data.location || "top";
      this.showSnackbar = true;
    },
  },
};
</script>
