<template>
  <v-row class="ma-2">
    <v-col sm="12" class="p-0" align="center" justify="center" v-if="loading">
      <v-progress-circular color="primary" height="10" indeterminate />
    </v-col>
    <v-row justify="center" v-else class="pa-4">
      <v-col cols="12" md="10" lg="10" class="pa-0">
        <p class="bold-h1">Order</p>
        <v-card class="pa-4 mt-4" flat>
          <v-window disabled v-model="step">
            <v-window-item disabled :value="1">
              <Vue3Lottie
                animation-link="https://assets2.lottiefiles.com/packages/lf20_iawwfiag.json"
                :height="200"
              />
            </v-window-item>
            <v-window-item disabled :value="4">
              <v-col align="center">
                <v-btn
                  @click="dialog = true"
                  color="primary"
                  size="x-large"
                  block
                  flat
                  >{{ $t("pickup_order") }}</v-btn
                >
                <v-dialog max-width="500" persistent v-model="dialog">
                  <v-card :title="$t('open_lockers')">
                    <v-card-text>
                      {{ $t("are_you_sure_you_want_to_open_lockers") }}
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="dialog = false"> {{ $t("no") }}</v-btn>

                      <v-btn @click="collectOrder" :loading="loadingCollect">
                        {{ $t("yes") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-window-item>
            <v-window-item disabled :value="5"> </v-window-item>
          </v-window>
          <span class="bold-h2"
            ><span class="bold-h2" style="color: #003a70">
              Nr.
              {{ order.shortOrderNum.toString().padStart(4, "0") }} </span
            ><span class="bold-h2 ml-4"> {{ $t(order.status) }}</span>
          </span>
          <p class="pt-4 body-1">
            {{
              order.pickupPoint.name +
              " " +
              getDayLabel(order.pickupTimeSlot.pickupAt, false) +
              " " +
              formatTime(order.pickupTimeSlot.pickupAt) +
              " - " +
              formatTime(order.pickupTimeSlot.pickupBefore)
            }}
          </p>
        </v-card>
        <v-expansion-panels class="pt-4">
          <v-expansion-panel elevation="0">
            <v-expansion-panel-title class="bold-h3">{{
              $t("items_count", { count: order.totalItems })
            }}</v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-card
                v-for="(product, index) in order.orderProductVariants"
                :key="product.id"
                class="pa-2 ma-2 mt-4"
                elevation="0"
              >
                <v-row>
                  <v-col
                    align="center"
                    justify="center"
                    cols="2"
                    sm="1"
                    class="pa-1"
                  >
                    <v-img
                      :src="product.thumbnail.url"
                      alt="Product Image"
                      class="hover"
                      height="100"
                      @click="handleProductClick(product)"
                    />
                  </v-col>
                  <v-col>
                    <v-col sm="12" class="pa-0">
                      <p class="bold-h4 pt-0">
                        {{ product.name }}
                      </p></v-col
                    >
                    <v-col sm="12" class="pa-0">
                      <p class="body-2">
                        {{ product.manufacturer + " " + product.displayWeight }}
                      </p></v-col
                    >
                  </v-col>
                  <v-col cols="4" sm="2" align="end">
                    <p class="bold-h4">
                      {{
                        product.price +
                        " /" +
                        (product.weightProduct
                          ? "kg"
                          : $t("piece_abbreviation"))
                      }}
                    </p>
                    <p class="bold-h4">
                      {{ product.quantity + " st" }}
                    </p>
                    <p class="bold-h4">
                      {{
                        (order.status == "delivered"
                          ? product.totalPrice.toFixed(2)
                          : product.reservedPrice.toFixed(2)) + " kr"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-expansion-panel-text></v-expansion-panel
          ></v-expansion-panels
        >
        <v-col class="pa-0 pt-4">
          <v-card class="pa-4 sticky-card" elevation="0">
            <p class="bold-h2">{{ $t("overview") }}</p>

            <v-col>
              <v-row justify="space-between" class="bold-h4">
                <p>{{ $t("delivery_cost") }}</p>
                <p>{{ order.price.deliveryFee.toFixed(2) }} kr</p>
              </v-row>
              <v-row justify="space-between" class="bold-h4">
                <span>{{ $t("item_cost") }}</span>
                <span>{{ order.price.products.toFixed(2) }} kr</span>
              </v-row>
              <v-row
                justify="space-between"
                class="bold-h4"
                style="color: #c94631"
              >
                <span>{{ $t("total_discount") }}</span>
                <span
                  >{{
                    (
                      order.price.productDiscount + order.price.cartDiscount
                    ).toFixed(2)
                  }}
                  kr</span
                >
              </v-row>
              <v-row justify="space-between" class="bold-h3 pt-10">
                <span>{{
                  $t("total") +
                  " (" +
                  $t("items_count", { count: order.totalItems }) +
                  ")"
                }}</span>
                <span
                  >{{
                    (order.status == "delivered"
                      ? order.price.total.toFixed(2)
                      : order.price.payment.toFixed(2)) + " kr"
                  }}
                </span>
              </v-row>

              <v-col class="pt-8 pa-0" v-if="order.status == 'delivered'">
                <v-row
                  justify="space-between"
                  class="body-2"
                  v-if="order.taxes._6.tax > 0"
                >
                  <span>{{ $t("including_vat_6_percent") }}</span>
                  <span>{{ order.taxes._6.tax.toFixed(2) + " kr" }} </span>
                </v-row>
                <v-row
                  justify="space-between"
                  class="body-2"
                  v-if="order.taxes._12.tax > 0"
                >
                  <span>{{ $t("including_vat_12_percent") }}</span>
                  <span>{{ order.taxes._12.tax.toFixed(2) + " kr" }} </span>
                </v-row>
                <v-row
                  justify="space-between"
                  class="body-2"
                  v-if="order.taxes._25.tax > 0"
                >
                  <span>{{ $t("including_vat_25_percent") }}</span>
                  <span>{{ order.taxes._25.tax.toFixed(2) + " kr" }} </span>
                </v-row>
              </v-col>
            </v-col>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import OrderAPI from "@/api/OrderAPI";
import { formatTime, getDayLabel } from "../utils.js";
import { Vue3Lottie } from "vue3-lottie";
import { mapState } from "vuex";
export default {
  name: "OrderPage",
  data() {
    return {
      orderId: "",
      order: {},
      activeOrders: {},
      previousOrders: {},
      loading: true,
      step: 1,
      orderTimer: null,
      dialog: false,
      loadingCollect: false,
    };
  },
  computed: {
    ...mapState(["currentLang", "categories"]),
  },
  deactivated() {
    console.log("unmounting...");
    clearInterval(this.orderTimer);
  },

  async activated() {
    try {
      console.log(this.$route);
      await this.fetchOrder();
      this.orderTimer = setInterval(this.fetchOrder, 5000);
      console.log(this.order);
      this.loading = false;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  },
  unmounted() {
    console.log("unmounting...");
    clearInterval(this.orderTimer);
  },

  watch: {
    "order.status": function (newStatus) {
      this.updateStep();
    },
    currentLang(newVal) {
      this.fetchOrder();
    },
  },
  methods: {
    getDayLabel,
    formatTime,
    async collectOrder() {
      try {
        this.loadingCollect = true;
        const resp = await OrderAPI.collectOrder(this.order._id);
        this.order = resp.data;
        this.loadingCollect = false;
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.loadingCollect = false;
      }
    },
    handleProductClick(product) {
      console.log("click product", product);
      this.$router.push({
        name: "ProductDetail",
        params: { id: product.productId },
      });
    },
    updateStep() {
      console.log();
      console.log(new Date(this.order.pickupTimeSlot.pickupAt));
      console.log(new Date(this.order.pickupTimeSlot.pickupBefore));
      if (
        this.order.status == "waiting" ||
        (new Date(this.order.pickupTimeSlot.pickupAt) < new Date() &&
          new Date(this.order.pickupTimeSlot.pickupBefore) > new Date())
      ) {
        this.step = 4;
      } else if (this.order.status == "delivered") {
        this.step = 5;
      } else {
        this.step = 1;
      }
    },
    async fetchOrder() {
      const resp = await OrderAPI.getOrder({ orderId: this.$route.params.id });
      this.order = resp.data;
    },
  },
};
</script>

<style scoped>
/* Add any styling here if needed */
</style>
