import axios from "axios";
import store from "@/store"; // Import your Vuex store
import { auth, firebaseConfig } from "@/plugins/firebaseconfig"; // Import the initialized auth instance and firebaseConfig

// Determines if the application is running in production mode
// const isProduction = import.meta.env.PROD;
const production = process.env.NODE_ENV
  ? process.env.NODE_ENV == "production"
  : false;

// Sets the backend endpoint based on the environment

const BACKEND_ENDPOINT = production
  ? process.env.VITE_APP_BACKEND_ENDPOINT
  : "/api";

/**
 * Creates an axios instance with predefined configuration
 * including baseURL, content type, and CORS settings.
 */
const axiosInstance = axios.create({
  baseURL: BACKEND_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

/**
 * Refreshes the Firebase ID token using the refresh token.
 * @returns {Promise<string>} - A promise that resolves to the new Firebase ID token.
 * @throws {Error} - Throws an error if the token refresh process fails.
 */
const refreshFirebaseToken = async () => {
  console.log("Starting token refresh...");
  try {
    const refreshToken = store.state.refreshToken;
    if (!refreshToken) {
      throw new Error("No refresh token available");
    }

    // Request to Firebase to refresh the ID token using the refresh token
    const response = await axios.post(
      `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`,
      {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      }
    );

    const newFirebaseToken = response.data.id_token;
    const newRefreshToken = response.data.refresh_token;

    // Store the new tokens in the Vuex store
    store.commit("setToken", newFirebaseToken);
    store.commit("setRefreshToken", newRefreshToken);

    console.log("New token set:", newFirebaseToken);

    return newFirebaseToken;
  } catch (error) {
    console.error("Error refreshing Firebase token:", error);
    throw error;
  }
};

// Axios request interceptor to add authentication token and store ID
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = store.state.token;

    // Retrieve the storeId from Vuex store
    const storeId = store.state.location.storeId;
    console.log("Store ID:", storeId);

    const lang = store.state.currentLang;
    console.log("lang:", lang);
    // Include the storeId in the request headers
    config.headers["storeId"] = storeId;
    config.headers["lang"] = lang;

    // Include the authentication token in the request headers
    if (token) {
      config.headers["X-ACCESS-TOKEN"] = token;
    }
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Axios response interceptor for handling responses and errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    console.error("Response error:", error);

    // If the error is due to an expired token
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        // Refresh the token using Firebase
        const newToken = await refreshFirebaseToken();

        // Update the token in Vuex store and localStorage
        store.commit("setToken", newToken);

        // Update the token in the original request headers
        originalRequest.headers["X-ACCESS-TOKEN"] = newToken;

        // Retry the original request with the new token
        return axiosInstance(originalRequest);
      } catch (tokenRefreshError) {
        console.error("Token refresh failed:", tokenRefreshError);
        // Handle user logout if token refresh fails
        store.dispatch("userLoggedOut");
        return Promise.reject(tokenRefreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
