import axiosInstance from "./AxiosInstance";
import store from "@/store"; // Import the Vuex store
import { auth } from "@/plugins/firebaseconfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCredential,
  OAuthProvider,
} from "firebase/auth";

/**
 * UserAPI provides methods for interacting with user authentication and user-related API endpoints.
 */
const UserAPI = {
  joinWaitlist: async (email) => {
    const body = { email: email };
    const response = await axiosInstance.post(`/invitation/waitList`, body);
    console.log(response);
    return response.data;
  },
  /**
   * Fetch user by id
   *
   * @param {string} userId - The email address to check for an existing user.
   * @returns {Promise<boolean>} - A promise that resolves to true if the user exists, false otherwise.
   * @throws {Error} - Throws an error if the API call fails.
   */
  getUser: async (userId) => {
    try {
      const response = await axiosInstance.get(`/user/${userId}`);
      console.log(response);
      return response.data;
    } catch (error) {
      // Handles specific error for non-existing user
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.reason.includes(`User with email '${email}'`)
      ) {
        return false;
      }
      throw error; // Rethrows error if it's a different issue
    }
  },

  /**
   * Verify phone number
   *
   * @param {string} phoneNumber - The phone number to check for an existing user.
   * @returns {Promise<boolean>} - A promise that resolves to true if the user exists, false otherwise.
   * @throws {Error} - Throws an error if the API call fails.
   */
  verifyPhoneNumber: async (phoneNumber, code) => {
    try {
      const payload = {
        number: phoneNumber,
        code: code,
      };
      const response = await axiosInstance.post(
        `/user/checkVerification`,
        payload
      );
      console.log(response);
      return response.data;
    } catch (error) {
      // Handles specific error for non-existing user
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.reason.includes(`User with email '${email}'`)
      ) {
        return false;
      }
      throw error; // Rethrows error if it's a different issue
    }
  },
  /**
   * Send verification code phone number
   *
   * @param {string} phoneNumber - The phone number to check for an existing user.
   * @returns {Promise<boolean>} - A promise that resolves to true if the user exists, false otherwise.
   * @throws {Error} - Throws an error if the API call fails.
   */
  sendCodePhoneNumber: async (phoneNumber) => {
    try {
      const payload = {
        number: phoneNumber,
      };
      const response = await axiosInstance.post(
        `/user/sendVerification`,
        payload
      );
      console.log(response);
      return response.data;
    } catch (error) {
      // Handles specific error for non-existing user
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.reason.includes(`User with email '${email}'`)
      ) {
        return false;
      }
      throw error; // Rethrows error if it's a different issue
    }
  },

  /**
   * Checks if a user exists by their email address.
   *
   * @param {string} email - The email address to check for an existing user.
   * @returns {Promise<boolean>} - A promise that resolves to true if the user exists, false otherwise.
   * @throws {Error} - Throws an error if the API call fails.
   */
  checkUserExists: async (params) => {
    try {
      const response = await axiosInstance.get("/user/userExists/check", {
        params,
      });
      return response.data;
    } catch (error) {
      // Handles specific error for non-existing user
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.reason.includes(`User with email '${email}'`)
      ) {
        return false;
      }
      throw error; // Rethrows error if it's a different issue
    }
  },

  /**
   * Updates user.
   *
   *
   * @returns {Promise<boolean>} - A promise that resolves to true if the user exists, false otherwise.
   * @throws {Error} - Throws an error if the API call fails.
   */
  updateUser: async (userId, body) => {
    try {
      const response = await axiosInstance.patch(`/user/${userId}`, body);
      return response.data;
    } catch (error) {
      // Handles specific error for non-existing user
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.reason.includes(`User with email '${email}'`)
      ) {
        return false;
      }
      throw error; // Rethrows error if it's a different issue
    }
  },

  /**
   * Registers a new user with the backend.
   *
   * @param {string} uid - The Firebase UID.
   * @param {string} email - The user's email address.
   * @param {string} accountType - The account type (email, google, apple).
   * @returns {Promise<Object>} - A promise that resolves to the backend response data.
   * @throws {Error} - Throws an error if the registration process fails.
   */
  registerNewUser: async (firebaseUser, accountType, phone) => {
    try {
      const payload = {
        _id: firebaseUser.uid,
        phone: phone,
        phoneVerified: true,
        email: firebaseUser.email,
        accountType: accountType,
        postalCode: "string",
        firstName: "string",
        lastName: "string",
        stripeId: "string",
        gender: "string",
        defaultShippingAddress: {
          firstName: "string",
          lastName: "string",
          line1: "string",
          line2: "string",
          email: "string",
          postalCode: "string",
          city: "string",
          country: "string",
          title: "string",
        },
        defaultBillingAddress: {
          firstName: "string",
          lastName: "string",
          line1: "string",
          line2: "string",
          email: "string",
          postalCode: "string",
          city: "string",
          country: "string",
          title: "string",
        },
        digitalReceipt: true,
        cards: [
          {
            id: "string",
            default: true,
            expMonth: "string",
            expYear: "string",
            lastFour: "string",
            brand: "string",
          },
        ],
        referredBy: "string",
      };

      const response = await axiosInstance.post("/user", payload);
      return response.data;
    } catch (error) {
      console.error("Error during user registration:", error);
      throw error; // Throws the error for further handling
    }
  },

  /**
   * Sign in a user with email and password.
   *
   * @param {string} email - The user's email address.
   * @param {string} password - The user's password.
   * @returns {Promise<Object>} - A promise that resolves to the user data and tokens.
   * @throws {Error} - Throws an error if the sign-in process fails.
   */
  signInWithEmail: async (email, password) => {
    try {
      // Firebase sign-in with email and password
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      /*const user = userCredential.user;

      // Get the ID token and refresh token
      const firebaseToken = await user.getIdToken();
      const refreshToken = user.refreshToken;

      // Send the Firebase token to the backend for login
      const response = await axiosInstance.post("/user/login", {
        firebaseToken,
        type: "email",
      });

      // Store the received JWT token and refresh token
      store.commit("setToken", response.data.token);
      store.commit("setRefreshToken", refreshToken);
*/
      return { firebaseUser: userCredential };
    } catch (error) {
      console.error("Error during sign in:", error);
      throw error; // Throws the error for further handling
    }
  },

  /**
   * Register a new user with email and password.
   *
   * @param {string} email - The user's email address.
   * @param {string} password - The user's password.
   * @returns {Promise<Object>} - A promise that resolves to the user data and tokens.
   * @throws {Error} - Throws an error if the sign-up process fails.
   */
  signUpWithEmail: async (email, password) => {
    try {
      // Firebase sign-up with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      /*const user = userCredential.user;

      const uid = user.uid;
      const refreshToken = user.refreshToken;

      // Check if the user already exists in the backend
      const userExists = await UserAPI.checkUserExists(email);
      let backendUser;

      if (!userExists) {
        // Register new user in the backend if not exists
        backendUser = await UserAPI.registerNewUser(uid, email, "email");
      } else {
        // Login existing user in the backend
        const firebaseToken = await user.getIdToken();
        const response = await axiosInstance.post("/user/login", {
          firebaseToken,
          type: "email",
        });
        backendUser = response.data.user;
        store.commit("setToken", response.data.token);
      }
*/
      return { firebaseUser: userCredential };
    } catch (error) {
      console.error("Error during sign up:", error);
      throw error; // Throws the error for further handling
    }
  },

  /**
   * Register a new user with email and password.
   *
   * @param {string} email - The user's email address.
   * @param {string} password - The user's password.
   * @returns {Promise<Object>} - A promise that resolves to the user data and tokens.
   * @throws {Error} - Throws an error if the sign-up process fails.
   */
  signInWithCredential: async (firebaseUser) => {
    try {
      // Firebase sign-up with email and password
      console.log(firebaseUser);
      const user = firebaseUser.user;

      const refreshToken = user.refreshToken;
      // Login existing user in the backend
      const firebaseToken = await user.getIdToken();
      const response = await axiosInstance.post("/user/login", {
        firebaseToken,
        type: "jwt",
      });
      const backendUser = response.data;
      store.commit("setToken", response.data.token);

      // Store the refresh token
      store.commit("setRefreshToken", refreshToken);

      return { user: backendUser, token: store.state.token, refreshToken };
    } catch (error) {
      console.error("Error during sign up:", error);
      throw error; // Throws the error for further handling
    }
  },

  /**
   * Sign in a user with Google.
   *
   * @returns {Promise<Object>} - A promise that resolves to the user data and tokens.
   * @throws {Error} - Throws an error if the Google sign-in process fails.
   */
  signInWithGoogle: async () => {
    try {
      const provider = new GoogleAuthProvider();
      // Firebase sign-in with Google
      const userCredential = await signInWithPopup(auth, provider);

      /* const user = userCredential.user;

      const uid = user.uid;
      const accessToken = user.accessToken;
      const refreshToken = user.refreshToken;
      const email = user.email;

      // Check if the user already exists in the backend
      const userExists = await UserAPI.checkUserExists(email);
      let backendUser;

      if (!userExists) {
        // Register new user in the backend if not exists
        backendUser = await UserAPI.registerNewUser(uid, email, "google");
      } else {
        // Login existing user in the backend
        const firebaseToken = await user.getIdToken();
        const response = await axiosInstance.post("/user/login", {
          firebaseToken,
          type: "google",
        });
        backendUser = response.data;
        store.commit("setToken", response.data.token);
      }

      // Store the refresh token
      store.commit("setRefreshToken", refreshToken);
*/
      return { firebaseUser: userCredential };
    } catch (error) {
      console.error("Error during Google sign in:", error);
      throw error; // Throws the error for further handling
    }
  },

  /**
   * Sign in a user with Apple.
   *
   * @returns {Promise<Object>} - A promise that resolves to the user data and tokens.
   * @throws {Error} - Throws an error if the Apple sign-in process fails.
   */
  signInWithApple: async () => {
    try {
      const provider = new OAuthProvider("apple.com");
      // Firebase sign-in with Apple
      const userCredential = await signInWithPopup(auth, provider);

      /* const user = userCredential.user;

      const uid = user.uid;
      const accessToken = user.accessToken;
      const refreshToken = user.refreshToken;
      const email = user.email;

      // Check if the user already exists in the backend
      const userExists = await UserAPI.checkUserExists(email);
      let backendUser;

      if (!userExists) {
        // Register new user in the backend if not exists
        backendUser = await UserAPI.registerNewUser(uid, email, "apple");
      } else {
        // Login existing user in the backend
        const firebaseToken = await user.getIdToken();
        const response = await axiosInstance.post("/user/login", {
          firebaseToken,
          type: "apple",
        });
        backendUser = response.data.user;
        store.commit("setToken", response.data.token);
      }

      // Store the refresh token
      store.commit("setRefreshToken", refreshToken);
*/
      return { firebaseUser: userCredential };
    } catch (error) {
      console.error("Error during Apple sign in:", error);
      throw error; // Throws the error for further handling
    }
  },

  sendPhoneEmail: async (body) => {
    try {
      const response = await axiosInstance.post("/user/send/numberUsed", body);
      console.log(response);
      return response.data;
    } catch (error) {
      // Handles specific error for non-existing user
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.reason.includes(`User with email '${email}'`)
      ) {
        return false;
      }
      throw error; // Rethrows error if it's a different issue
    }
  },
};

export default UserAPI;
