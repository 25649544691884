// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Firebase configuration for production and development environments
const firebaseProdConfig = {
  apiKey: "AIzaSyC0HADoySFEt4KSR2se7T5ykz3ebdnZcgM",
  authDomain: "nearbystore-5ef57.firebaseapp.com",
  projectId: "nearbystore-5ef57",
  storageBucket: "nearbystore-5ef57.appspot.com",
  messagingSenderId: "250670542383",
  appId: "1:250670542383:web:782d6b508f97b40b6120da",
  measurementId: "G-3W48L3H7YV",
};

const firebaseDevConfig = {
  apiKey: "AIzaSyDfFKl_TkmuJ-IOII7eWaCFGCCYsI-xW7k",
  authDomain: "nearbystore-dev.firebaseapp.com",
  projectId: "nearbystore-dev",
  storageBucket: "nearbystore-dev.appspot.com",
  messagingSenderId: "890691244196",
  appId: "1:890691244196:web:1f7d0fd2448e25debb5631",
  measurementId: "G-P15B2NTTDX",
};

const production = process.env.VITE_APP_FIREBASE_DEVELOPMENT
  ? process.env.VITE_APP_FIREBASE_DEVELOPMENT == "production"
  : false;
console.log("this is procces.env");
console.log(process.env.VITE_APP_FIREBASE_DEVELOPMENT);
const firebaseConfig = production ? firebaseProdConfig : firebaseDevConfig;
console.log(firebaseConfig);
// Initialize Firebase

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const secondaryApp = initializeApp(firebaseConfig, "Secondary");

// Initialize Firebase Authentication
const auth = getAuth();
const secondaryAuth = getAuth(secondaryApp);

export { app, auth, secondaryApp, secondaryAuth, firebaseConfig };
