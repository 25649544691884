<template>
  <v-container fluid>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>

    <v-col align="start" justify="start" v-else>
      <Breadcrumbs :items="breadcrumbItems" />
      <p class="bold-h1">{{ list.name }}</p>
      <p class="body-1">
        {{ $t("items_count", { count: list.activeProducts.length }) }}
      </p>
      <p class="bold-h2">Produkter</p>
      <v-row justify="center">
        <v-col cols="12" md="10" justify="center">
          <v-card
            v-for="(product, index) in list.activeProducts"
            :key="product._id"
            class="pa-2 ma-2"
            elevation="0"
          >
            <v-row>
              <v-col
                align="center"
                justify="center"
                cols="2"
                style="position: relative"
              >
                <DiscountPrice
                  v-if="product.promotion"
                  style="
                    position: absolute;
                    top: 3px;
                    right: 0px;
                    z-index: 1000;
                  "
                  :size="
                    !$vuetify.display.xs
                      ? { width: 60, height: 60 }
                      : { width: 45, height: 45 }
                  "
                  :promotion="product.promotion"
                  :isWeight="product.weightProduct"
                />
                <v-img
                  :src="product.thumbnail.url"
                  alt="Product Image"
                  class="hover"
                  height="100"
                  @click="handleProductClick(product)"
                />
              </v-col>
              <v-col align="space-between">
                <v-row class="pl-3 pr-3 pt-1">
                  <p class="bold-1">
                    {{ product.name }}
                  </p>
                </v-row>
                <v-row class="pl-3 pr-3 mt-1" align="end">
                  <v-col class="pa-0" justify="center">
                    <v-col sm="12" class="pa-0 pr-1">
                      <p class="body-3">
                        {{ product.manufacturer + " " + product.displayWeight }}
                      </p></v-col
                    ></v-col
                  >
                  <v-col class="pa-0 pl-1" cols="5" sm="3">
                    <ProductButton :product="product" />
                  </v-col>
                </v-row>

                <p
                  class="bold-h3 mt-4"
                  style="color: #c94631"
                  v-if="
                    product.promotion != null &&
                    product.promotion.minQuantity == 1
                  "
                >
                  <span> {{ product.promotion.discountValue.toFixed(2) }}</span>
                  {{
                    "/" +
                    (product.weightProduct ? "kg" : $t("piece_abbreviation"))
                  }}
                </p>

                <p class="bold-h4 mt-4" v-else>
                  <span> {{ product.price }}</span>
                  {{
                    "/" +
                    (product.weightProduct ? "kg" : $t("piece_abbreviation"))
                  }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import Header from "@/components/Header.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatTime, getDayLabel } from "../utils.js";
import ProductButton from "../components/ProductButton.vue";
import ShoppingListAPI from "@/api/SaveAPI.js";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import { useI18n } from "vue-i18n";
export default {
  name: "ListDetail",
  components: {
    Header,
    ProductButton,
    Breadcrumbs,
  },
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data() {
    return {
      loading: true,
      list: {},
    };
  },

  computed: {
    ...mapState([
      "cart",
      "localCart",
      "location",
      "isCartLoading",
      "user",
      "locationModalIsVisible",
    ]),
    breadcrumbItems() {
      // Initialize breadcrumbs with the home page
      let items = [
        {
          text: this.t("home"),
          to: {
            name: "handla",
          },
        },
        ...(this.user != null && this.user._id == this.list.userId
          ? [
              {
                text: this.t("saved"),
                to: {
                  name: "saved",
                },
              },
            ]
          : []),

        {
          text: this.list.name,
          disabled: true,
        },
      ];
      return items;
    },
  },
  activated() {
    this.fetchShoppingList();
  },
  async mounted() {
    this.fetchShoppingList();
  },
  methods: {
    ...mapActions([
      "toggleLocationModal",
      "setCategoryModal",
      "toggleCartModal",
      "addToCart",
      "removeFromCart",
      "clearCart",
      "checkoutCart",
    ]),
    handleProductClick(product) {
      console.log("click product", product);
      this.$router.push({
        name: "ProductDetail",
        params: { id: product._id },
      });
    },
    async fetchShoppingList() {
      try {
        this.loading = true;
        const response = await ShoppingListAPI.getShoppingListById(
          this.$route.params.id
        );
        this.list = response.data;
        this.loading = false;
      } catch (error) {
        console.error("Error loading shopping lists:", error);
      } finally {
      }
    },
  },
};
</script>
