<template>
  <v-col>
    <div class="bold-h1 mb-10">{{ $t("offers") }}</div>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular
    ></v-row>

    <AllProducts v-else :products="allProducts" @loadMore="handleLoadMore" />
  </v-col>
</template>

<script>
import AllProducts from "../components/AllProducts.vue";
import CategoryProductAPI from "@/api/CategoryProductAPI";

export default {
  name: "Offer",
  emits: ["loadMore"],
  components: {
    AllProducts,
  },
  data() {
    return {
      allProducts: [],
      loading: true,
      page: 1,
    };
  },
  async mounted() {
    console.log("loading");
    await this.fetchProducts();
    this.loading = false;
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await CategoryProductAPI.getProducts({
          page: this.page,
          offers: true,
        });
        this.allProducts = [...this.allProducts, ...response.data];
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },

    handleLoadMore() {
      page.value += 1;
      fetchProducts();
    },
  },
};
</script>

<style scoped>
.grid-container {
  width: 100%;
  margin-bottom: 125px;
  position: relative;
}

.phone-wrapper {
  width: 95%;
  margin: auto;
}
</style>
