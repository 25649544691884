<template>
  <v-app-bar class="pa-1 pr-2" app color="primary" dense dark flat>
    <v-row align="center" justify="start">
      <v-col sm="2" class="ma-4" @click="goToHome">
        <v-img :src="logo" contain class="hover pa-4"></v-img>
      </v-col>
      <v-col sm="5" md="6" lg="5" v-if="!isPhone && !isTablet && !hideButtons">
        <v-row justify="space-around">
          <v-btn
            variant="text"
            class="button text-none"
            @click="$router.push({ name: 'handla' })"
            >{{ $t("shop") }}</v-btn
          >
          <v-btn
            variant="text"
            class="button text-none"
            @click="$router.push({ name: 'offers' })"
            >{{ $t("offers") }}</v-btn
          >

          <!--  <v-btn variant="text" class="button text-none">{{
            $t("recipes")
          }}</v-btn>-->
          <v-btn
            variant="text"
            class="button text-none"
            @click="$router.push({ name: 'howDoesItWork' })"
          >
            {{ $t("how_it_works") }}</v-btn
          >
          <v-btn
            variant="text"
            class="button text-none"
            @click="$router.push({ name: 'customerService' })"
            >{{ $t("customer_service") }}</v-btn
          >
        </v-row>
      </v-col>

      <v-row v-if="!hideButtons" align="center" justify="end" class="mr-2">
        <v-col cols="12" sm="3" align="end">
          <v-menu location="bottom" v-if="user">
            <template v-slot:activator="{ props }">
              <v-btn color="surface" class="button text-none" v-bind="props"
                ><v-icon class="pr-3">mdi-account-circle</v-icon
                >{{ $t("profile") }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="goToOrders">
                <v-list-item-title>{{ $t("my_orders") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToSaved">
                <v-list-item-title>{{ $t("saved") }}</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="goToSettings">
                <v-list-item-title>{{ $t("profile") }}</v-list-item-title>
              </v-list-item> -->
              <v-container class="pr-2 pl-2 pb-0 pt-0 ma-0">
                <v-btn color="error" block elevation="0" @click="logout">{{
                  $t("log_out")
                }}</v-btn>
              </v-container>
            </v-list>
          </v-menu>
          <v-btn v-else @click="goToLogin" class="text-body-1">
            <span>{{ $t("log_in") }}</span>
            <v-icon large class="pl-2">mdi-account-circle</v-icon>
            <LoginModal
              :visible="loginModal"
              @close="loginModal = false"
            ></LoginModal>
          </v-btn>
        </v-col>
        <v-col
          v-if="!isPhone && !isTablet && !hideButtons"
          class="mt-5 button text-none"
          md="4"
          lg="3"
        >
          <v-select
            v-model="currentLang"
            variant="outlined"
            bg-color="primary"
            item-title="value"
            :items="[
              { name: 'Svenska', value: 'sv', flagValue: 'se' },
              { name: 'English', value: 'en-US', flagValue: 'gb' },
            ]"
            density="compact"
            single-line
            style="max-width: 90px"
          >
            <template #selection="item">
              <v-row class="pa-0 ma-0" align="center"
                ><country-flag
                  :country="item.item.raw.flagValue"
                  size="normal"
                  class="mr-2 mb-0"
                />
              </v-row>
            </template>
            <template #item="{ item, props: { onClick } }">
              <v-list-item @click="onClick" justify="center" class="bold-1">
                <v-row class="pa-0 ma-0" align="center"
                  ><country-flag
                    :country="item.raw.flagValue"
                    size="normal"
                    class="mr-2 mb-0"
                  />
                  <p>{{ item.raw.name }}</p></v-row
                >
              </v-list-item>
            </template></v-select
          >
        </v-col>
      </v-row>
    </v-row>
  </v-app-bar>

  <!-- Location selection component, positioned just below the header -->

  <!-- Search page component, positioned just below the header 
  <SearchPage />-->
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import CartModal from "@/components/cartLogic/CartModal.vue";
import SearchPage from "@/components/smallDevicesOnly/SearchPhonesTablets.vue";
import LoginModal from "@/components/LoginModal.vue";
import logo from "../assets/images/ns_white.png";
import logo_small from "../assets/images/ns_white_small.png";
import { formatTime, getDayLabel } from "../utils.js";
import { useI18n } from "vue-i18n";
import CountryFlag from "vue-country-flag-next";
export default {
  name: "Header",
  components: {
    CartModal,
    SearchPage,
    CountryFlag,
  },
  data() {
    return {
      logo_small: logo_small,
      logo: logo,
      loginModal: false,
      searchTerm: "",
      isPhone: false,
      isTablet: false,
      lang: "Svenska",
    };
  },
  watch: {
    lang(newVal, oldVal) {
      const { locale } = useI18n();
      locale.value = "en";
      console.log(newVal);
    },
  },
  computed: {
    ...mapFields(["currentLang"]),
    ...mapState([
      "localCart",
      "isCartLoading",

      "user",
      "isSearchVisible",
      "location",
    ]),
    ...mapGetters(["isProfileVisible"]),

    hideButtons() {
      if (this.$route.meta.hideButtons != undefined) {
        return this.$route.meta.hideButtons;
      } else {
        return false;
      }
    },
    totalCartItems() {
      return this.localCart.totalItems;
    },
  },
  methods: {
    ...mapActions(["toggleLocationModal", "toggleCartModal", "userLoggedOut"]),
    formatTime,
    getDayLabel,
    goToLogin() {
      this.loginModal = true;
    },
    goToHome() {
      this.$router.push({ path: "/" });
    },
    goToOrders() {
      this.$router.push({ name: "orders" });
    },
    openLocationSelection() {
      this.toggleLocationModal();
    },
    openCartModal() {
      this.toggleCartModal();
    },
    goToProfile() {
      this.$router.push({ name: "profile" });
    },
    goToSaved() {
      this.$router.push({ name: "saved" });
    },
    goToSettings() {
      this.$router.push({ name: "settings" });
    },
    logout() {
      this.userLoggedOut();
    },
    checkDevice() {
      const width = window.innerWidth;
      this.isPhone = width <= 600;
      this.isTablet = width > 600 && width <= 1024;
    },
  },
  mounted() {
    this.checkDevice();
    window.addEventListener("resize", this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
};
</script>

<style scoped>
:deep(.v-field__input) {
  height: 45px;
  padding: 0;
  padding-left: 15px;
}
.hover {
  cursor: pointer;
}
.header {
  overflow: visible;
}
.text-field {
  caret-color: black;
}

/* Styles for the logo container */
.logo-container {
  cursor: pointer;
  flex: 0 0 330px; /* Fixed size for logo */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Styles for hiding the logo on phones */
.phone-logo {
  flex: 0 0 80px;
}

/* Styles for the logo on tablets */
.tablet-logo {
  flex: 0 0 240px;
}

/* Styles for the header image */
.header-image {
  max-width: 250px;
  margin: auto 10px auto 40px;
}

/* Styles for the left container with the search field */
.left-container {
  flex-grow: 1; /* Allow this container to grow */
  display: flex;
  align-items: center;
  padding-left: 10px; /* Added padding */
}

/* Styles for the search field */
.search-field {
  width: 100%; /* Take full width of its container */
  background-color: white;
  border-radius: 5px;
  color: black;
}

/* Styles for the right container with action buttons */
.right-container {
  flex-grow: 1; /* Allow this container to grow */
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items to the right */
  padding-right: 10px; /* Added padding */
}

/* Styles for the search icon */
.search-icon {
  color: white;
  font-size: 32px; /* Larger font size for the search icon */
  margin-right: 10px; /* Margin to the right of the search icon */
}

/* Styles for the cart icon */
.cart-icon {
  padding: 25px;
  border-radius: 5px;
  background-color: #c94631;
}

/* Styles for the cart icon */
.cart-btn >>> .v-btn__underlay {
  background-color: #c94631;
  border-radius: 5px;
}

/* Styles for the profile and dropdown text */
.profile-text,
.dropdown-text {
  margin-right: 10px;
}

/* Uniform margin for buttons */
.profile-btn,
.pickup-btn,
.cart-btn {
  margin: 0 10px;
}

/* Styles for the enlarged profile button */
.profile-btn-large {
  font-size: 20px; /* Increase font size */
  padding: 15px; /* Increase padding */
  margin-right: 20px; /* Move more to the right */
}

/* Dropdown menu styles */
.profile-dropdown-container {
  position: relative;
}

.dropdown-menu {
  margin-top: 25px;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ebebeb; /* Dropdown background color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 4px;
  overflow: hidden;
}

.dropdown-item {
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  color: black; /* Dropdown text color */
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-logout-btn {
  background-color: #c94631;
  color: white;
  border: none;
  margin: 10px;
  padding: 10px 20px;
  cursor: pointer;
  text-align: left;
}

.dropdown-logout-btn:hover {
  background-color: darkred;
}

/* Responsive styles for smaller screens */
@media (max-width: 600px) {
  .logo-container {
    flex: 0 0 100px; /* Adjust logo size for phones */
  }
  .dropdown-text,
  .profile-text {
    display: none; /* Hide on small screens */
  }
  .profile-btn,
  .pickup-btn,
  .cart-btn {
    min-width: 48px; /* Touch target size */
  }
}
</style>
