<template>
  <div class="grid-container pr-2 pl-2">
    <!-- Vuetify Loading Component -->
    <v-row v-if="loading" justify="center" align="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular
    ></v-row>

    <!-- Content to display when not loading -->
    <div v-else>
      <div v-if="isPhone || isTablet">
        <PhoneSearchField />
      </div>

      <!-- Render BannerContainer only if storeId is present -->
      <BannerContainer class="mt-5" v-if="storeId" />

      <!-- Loop through each text banner and display FeaturedProducts for each -->
      <div v-for="banner in textBanners" :key="banner._id">
        <FeaturedProducts
          :featuredText="banner.title"
          :products="banner.products"
          :bannerId="banner._id"
        />
      </div>

      <!-- AllProducts section -->
      <keep-alive>
        <v-col class="mt-10 pa-0">
          <p class="mb-10 bold-h1">{{ $t("all_products") }}</p>
          <AllProducts
            :products="allProducts"
            @loadMore="handleLoadMore"
            :loadingMoreProducts="loadingMoreProducts"
          />
        </v-col>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import BannerContainer from "./ProductBanner.vue";
import FeaturedProducts from "./FeaturedProducts.vue";
import AllProducts from "./AllProducts.vue";
import BannerAPI from "@/api/BannerAPI";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import PhoneSearchField from "./SearchField.vue";
import CategoryNavigationMobile from "@/components/smallDevicesOnly/CategoryNavigationPhones.vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { onMounted } from "vue";

export default {
  name: "ProductGrid",
  emits: ["loadMore"],
  components: {
    BannerContainer,
    FeaturedProducts,
    PhoneSearchField,
    AllProducts,
    CategoryNavigationMobile,
  },
  setup() {
    const store = useStore();
    const allProducts = ref([]);
    const textBanners = ref([]);
    const loading = ref(true); // Tracks if the data is still loading
    const page = ref(1);
    const isPhone = ref(false);
    const loadingMoreProducts = ref(false);
    const isTablet = ref(false);

    const storeId = computed(() => store.state.location.storeId);

    const fetchTextBanners = async () => {
      if (!storeId.value) return;
      try {
        const response = await BannerAPI.getAllBanners({ textBanner: true });
        textBanners.value = response.data;
      } catch (error) {
        console.error("Error fetching text banners:", error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await CategoryProductAPI.getProducts({
          page: page.value,
          storeId: storeId.value,
        });
        allProducts.value = [...allProducts.value, ...response.data];
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    watch(
      () => store.state.currentLang,
      async () => {
        console.log("lang changed...!");

        try {
          fetchTextBanners();
          const limit = allProducts.value.length;
          console.log("limit", limit);
          const response = await CategoryProductAPI.getProducts({
            limit: limit,
          });
          allProducts.value = response.data;
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      }
    );
    const updateLoadingState = () => {
      if (!storeId.value) {
        loading.value = allProducts.value.length === 0;
      } else {
        loading.value =
          textBanners.value.length === 0 || allProducts.value.length === 0;
      }
    };

    const handleLoadMore = async () => {
      loadingMoreProducts.value = true;
      page.value += 1;
      await fetchProducts();
      loadingMoreProducts.value = false;
    };

    const checkDevice = () => {
      const width = window.innerWidth;
      isPhone.value = width <= 600;
      isTablet.value = width > 600 && width <= 1024;
    };
    onMounted(() => {
      loading.value = true;
      page.value = 1;
      allProducts.value = [];
      fetchTextBanners();
      fetchProducts();
    });

    // Watch for changes in banners and products to update the loading state
    watch([textBanners, allProducts], updateLoadingState);

    // Initialize device check
    checkDevice();
    window.addEventListener("resize", checkDevice);

    return {
      allProducts,
      handleLoadMore,
      storeId,
      loadingMoreProducts,
      textBanners,
      loading,
      isPhone,
      isTablet,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
};
</script>

<style scoped>
.grid-container {
  width: 100%;
  margin-bottom: 125px;
  position: relative;
}

.phone-wrapper {
  width: 95%;
  margin: auto;
}
</style>
